<template>
  <div
    class="list"
    style="height: inherit"
  >
    <b-alert
      variant="primary"
      class="mb-2"
      show
    >
      <div class="alert-body amount-text">
        等待司机取件：<strong>{{ shipment_amount }}</strong>个运单，共计<strong>{{ shipment_package_amount }}</strong>个包裹
      </div>
    </b-alert>
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-form-group
              label-for="shipment_id"
            >
              <b-form-input
                id="shipment_id"
                v-model="form.shipment_id"
                placeholder="运单ID"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-form-group
              label-for="recipient"
            >
              <b-form-input
                id="recipient"
                v-model="form.recipient"
                placeholder="收件人"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-form-group
              label-for="agent_order_id"
            >
              <b-form-input
                id="agent_order_id"
                v-model="form.agent_order_id"
                placeholder="预报单号"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-form-group
              label-for="shipment_waybill"
            >
              <b-form-input
                id="shipment_waybill"
                v-model="form.shipment_waybill"
                placeholder="物流号码"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-form-group
              label-for="country_id"
            >
              <v-select
                id="country_id"
                v-model="form.country"
                placeholder="国家或地区"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="countryOption"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-form-group
              label-for="courier"
            >
              <v-select
                id="courier"
                v-model="form.courier_text"
                placeholder="物流线路"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="courierOption"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-form-group>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="提交时间 ~ 结束时间"
                :config="{ mode: 'range'}"
                @on-change="handleRangeChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="relief-primary"
              class="mr-1"
              @click="handleSearch"
            >
              搜索
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="relief-secondary"
              @click="handleReset"
            >
              重置
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-row>
      <!-- <b-col
        xl="4"
        lg="4"
        md="4"
        sm="4"
      >
        <b-card>
          <div class="d-flex justify-content-start align-items-center">
            <span class="card-text">操作区</span>
            <b-button
              variant="relief-success"
              @click="handlePrint"
            >
              打印运单
            </b-button>
          </div>
        </b-card>
      </b-col> -->
      <b-col
        xl="12"
        lg="12"
        md="12"
        sm="12"
      >
        <b-card>
          <div class="d-flex justify-content-start align-items-center">
            <span class="card-text">筛选区</span>
            <b-button
              variant="relief-info"
              @click="handleFilter('paid_agent')"
            >
              代理点已揽收({{ shipment_amount }})
            </b-button>
            <b-button
              variant="relief-success"
              class="ml-1"
              @click="handleFilter('driverGetStock')"
            >
              司机已取货({{ driverData.shipment_amount }})
            </b-button>
            <b-button
              variant="relief-secondary"
              class="ml-1"
              @click="handleFilter('')"
            >
              所有状态
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Prodcuts -->
    <section>
      <b-card>
        <div
          v-if="chooseShipment"
          class="mb-1"
        >
          <span class="choose-text mr-1">您当前选择的是 {{ chooseShipment }}</span>
          <b-button
            variant="relief-success"
            @click="handlePrint"
          >
            打印运单
          </b-button>
        </div>
        <!-- table -->
        <vue-good-table
          ref="my-table"
          :columns="columns"
          :rows="rows"
          :row-style-class="rowStyleClass"
          :fixed-header="true"
          max-height="600px"
          style-class="vgt-table"
          :rtl="direction"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            setCurrentPage: 3,
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageInfo.perPage,
          }"
          @on-row-click="onRowClick"
        >
          <div slot="emptystate">
            <div
              v-if="loading"
              class=" d-flex justify-content-center align-items-center"
            >
              <b-spinner
                variant="primary"
                class="mr-1"
                type="grow"
              />
              <div class="loading-text">
                Loading...
              </div>
            </div>
            <div
              v-else
              class=" d-flex justify-content-center"
            >
              暂无内容
            </div>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'client_id'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.client_id }} {{ props.row.first_name }}{{ props.row.last_name }}</span>
            </span>

            <!-- Column: receiver -->
            <span
              v-else-if="props.column.field === 'receiver'"
              class="text-nowrap"
            >
              <span class="d-block">{{ props.row.delivery_to }}</span>
            </span>

            <!-- Column: package_num -->
            <span
              v-else-if="props.column.field === 'package_num'"
              class="text-nowrap"
            >
              <span class="d-block">{{ props.row.package_num }}</span>
              <spa style="color: red; font-weight: bold;" v-if="props.row.is_include_battery">(含电池)</spa>
            </span>

            <!-- Column: total_estimate_cost -->
            <span
              v-else-if="props.column.field === 'total_estimate_cost'"
              class="text-nowrap"
            >
              <span class="text-nowrap">AU${{ props.row.total_estimate_cost }}</span>
            </span>

            <!-- Column: shipment_status -->
            <span v-else-if="props.column.field === 'shipment_status'">
              <b-badge :variant="statusVariant(props.row.shipment_status)">
                {{ props.row.shipment_status === 'paid_agent' ? '代理点已揽收' : props.row.shipment_status_note }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  v-if="handleIsShowTrack(props.row.trackData)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="padding:5px"
                  variant="flat-success"
                >
                  追踪
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="padding:5px"
                  variant="flat-primary"
                  @click="$router.push(`/shipment/detail/${props.row.id}`)"
                >
                  查看
                </b-button>
                <b-button
                  v-if="props.row.shipment_status === 'paid_agent'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  style="padding:5px"
                  variant="flat-danger"
                  @click="handleCancel(props.row.id)"
                >
                  取消
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap pl-1 pr-1 pb-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageInfo.perPage"
                  size="sm"
                  :options="['10','20','30','50']"
                  class="mx-1"
                  @change="handlePerPageChange"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ pageInfo.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  aria-controls="my-table"
                  :value="pageInfo.page"
                  :total-rows="pageInfo.total"
                  :per-page="pageInfo.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @page-click="handlePageClick"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </section>
  </div>
</template>
<script>
import {
  BCol, BRow, BForm, BFormGroup, BFormInput, BBadge, BPagination, BFormSelect, BCard, BButton,
  BSpinner, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import dayjs from 'dayjs'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BBadge,
    BPagination,
    BFormSelect,
    BCard,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BButton,
    VueGoodTable,
    flatPickr,
    vSelect,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      form: {
        shipment_id: '',
        recipient: '',
        agent_order_id: '',
        shipment_waybill: '',
        country_id: '',
        country: '',
        courier: '',
        courier_text: '',
        created_at_start: '',
        created_at_end: '',
        status: '',
      },
      rangeDate: '',
      filters: {
        total: 0,
      },
      sortBy: { text: '时间', value: '1' },
      sortByOptions: [
        { text: '时间', value: '1' },
        { text: '价格', value: '2' },
      ],
      sortByTable: { text: '变更为已支付', value: '1' },
      sortByTableOptions: [
        { text: '变更为已支付', value: '1' },
        { text: '价格', value: '2' },
      ],
      dir: false,
      columns: [
        {
          label: '运单ID',
          field: 'shipment_id',
        },
        {
          label: '收件人',
          field: 'receiver',
        },
        {
          label: '线路名称',
          field: 'courier_name',
        },
        {
          label: '包裹数量',
          field: 'package_num',
        },
        {
          label: '收费',
          field: 'total_estimate_cost',
        },
        {
          label: '运单状态',
          field: 'shipment_status',
          width: '120px',
        },
        {
          label: '操作',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      status: [{
        1: 'voided',
        2: 'shipped',
        3: 'pay_now',
        4: 'submitted',
      },
      {
        1: 'light-secondary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-info',
      }],
      pageInfo: {
        page: 1,
        perPage: 10,
      },
      countryOption: [],
      courierOption: [],
      loading: false,
      rowStyleClass: '',
      clickShipmentId: '',
      chooseShipment: '',
      shipment_amount: 0,
      shipment_package_amount: 0,
      driverData: {},
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        voided: 'light-secondary',
        shipped: 'light-success',
        pay_now: 'light-danger',
        submitted: 'light-warning',
        packing: 'light-primary',
        paid_agent: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    'pageInfo.keyword': function (val) {
      if (!val) {
        this.initState()
      }
    },
    'form.country': function (val) {
      if (val && val.id) {
        this.form.country_id = val.id
        this.form.country = val.country
      }
    },
    'form.courier_text': function (val) {
      if (val && val.courier_id) {
        this.form.courier = val.courier_id
        this.form.courier_text = val.name
      }
    },
  },
  created() {
    // const currentPage = localStorage.getItem('currentPage')
    // if (currentPage) {
    //   this.$set(this.pageInfo, 'page', Number(currentPage))
    // }
    this.initState()
    this.initCountry()
    this.getAwaitingInfo()
  },
  methods: {
    async getAwaitingInfo() {
      try {
        const { data } = await this.$api.statisticsAgentAmount({ status: 'paid_agent' })
        const { data: driverData } = await this.$api.statisticsAgentAmount({ status: 'driverGetStock' })
        this.shipment_amount = data.shipment_amount
        this.shipment_package_amount = data.shipment_package_amount
        this.driverData = driverData
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleCancelShipment(id) {
      try {
        const data = await this.$api.deleteShipment({ id })
        if (data.code === 200) {
          this.$bvToast.toast('取消成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
          this.initState()
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    handleCancel(id) {
      this.$bvModal.msgBoxConfirm(`确定取消${id}这个运单吗？`, {
        title: '取消运单',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.handleCancelShipment(id)
        }
      })
    },
    onRowClick(params) {
      const parent = params.event.target.parentNode
      const clickables = document.querySelectorAll('.clickable')
      this.clickShipmentId = params.row.id
      this.chooseShipment = params.row.shipment_status === 'voided' ? '' : params.row.shipment_id
      if (parent) {
        clickables.forEach((item, idx) => {
          if (idx === params.pageIndex && params.row.shipment_status !== 'voided') {
            // eslint-disable-next-line
            item.style.background = '#F1F5FD'
          } else {
            // eslint-disable-next-line
            item.style.background = ''
          }
        })
      }
    },
    handleFilter(status = 'paid_agent') {
      // eslint-disable-next-line
      for (const k in this.form) {
        this.form[k] = ''
      }
      this.form.status = status
      this.initState()
    },
    handleIsShowTrack(trackData) {
      if (trackData && trackData.length) {
        return trackData.every(item => item.no && item.url)
      }
      return false
    },
    async handlePrint() {
      try {
        if (this.clickShipmentId) {
          const data = await this.$api.shipmentPrint({ id: this.clickShipmentId })
          if (data.code === 200) {
            const printData = data.data
            if (printData.declare) {
              if (printData.declare.is_default) {
                printData.declare.isChoose = printData.declare.is_default
              } else {
                printData.declare.isChoose = false
              }
            }
            if (printData.shipment) {
              if (printData.shipment.is_default) {
                printData.shipment.isChoose = printData.shipment.is_default
              } else {
                printData.shipment.isChoose = false
              }
            }
            if (printData.lineclear) {
              if (printData.lineclear.is_default) {
                printData.lineclear.isChoose = printData.lineclear.is_default
              } else {
                printData.lineclear.isChoose = false
              }
            }
            localStorage.setItem('print_data', JSON.stringify(printData))
            window.open('/print.html', '_blank')
          }
        } else {
          this.$bvModal
            .msgBoxConfirm('请选择一条数据', {
              cancelVariant: 'outline-danger',
              centered: true,
              buttonSize: 'sm',
            })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async initCountry() {
      try {
        const value = localStorage.getItem('userInfo')
        const userInfo = JSON.parse(value)
        const { data } = await this.$api.countryList({ page: 1, perPage: 1000 })
        const { data: cdata } = await this.$api.courierList({ agentId: `${userInfo.id}` })
        this.countryOption = data.map(item => ({
          title: item.country,
          ...item,
        }))
        this.courierOption = cdata.map(item => ({
          title: item.name,
          ...item,
        }))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    handleReset() {
      // eslint-disable-next-line
      for (const k in this.form) {
        this.form[k] = ''
      }
      this.initState()
    },
    handleSearch() {
      this.pageInfo.page = 1
      this.form.status = ''
      this.initState()
    },
    handleRangeChange(e) {
      this.form.created_at_start = dayjs(e[0]).format('YYYY-MM-DD')
      this.form.created_at_end = dayjs(e[1]).format('YYYY-MM-DD')
    },
    async initState() {
      try {
        this.rows = []
        this.loading = true
        const data = await this.$api.shipmentList({ ...this.pageInfo, ...this.form })
        this.loading = false
        if (data.code === 200) {
          this.rows = data.data.shipments
          this.pageInfo.total = data.data.counts
          if (this.pageInfo.status === '') {
            this.filters.total = data.data.counts
          }
        } else {
          this.$bvToast.toast(data.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
      } catch (e) {
        this.loading = false
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },
    handlePerPageChange(val) {
      this.pageInfo.perPage = val
      this.initState()
    },
    handlePageClick(e, page) {
      localStorage.setItem('currentPage', page)
      this.pageInfo.page = page
      this.initState()
    },
    handleSearchEnter() {
      this.initState()
    },
    resetPageInfo() {
      this.pageInfo.page = 1
      this.pageInfo.perPage = 10
      this.pageInfo.keyword = ''
      this.pageInfo.status = ''
    },
    handleAll() {
      this.resetPageInfo()
      this.initState()
    },
    handlePay() {
      this.resetPageInfo()
      this.pageInfo.status = 'pay_now'
      this.initState()
    },
    handleSubmitFilter(val) {
      this.initState(val)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.card-wrap{
  padding: 140px 0;
}
.number-box{
  margin: 200px;
}
.loading-text{
  font-size: 26px;
}
.show-text{
  display:block;
  word-break: keep-all;
  margin-right:10px;
}
.list ::v-deep table.vgt-table td{
  vertical-align: middle;
}
.list ::v-deep ul.dropdown-menu.dropdown-menu-right.show{
  z-index:11;
}
.card-text{
  font-size: 18px;
  margin-right: 30px;
  border-right: 2px solid #FFAF2A;
  padding-right: 10px;
}
.choose-text{
  font-size: 18px;
}
.amount-text{
  font-size: 20px;
}
</style>
